<template>
  <div class="resetPassword">
    <div class="topBar stickTop">
      <div class="logoIcon">
        <img src="images/logo-white.png" alt="" />
      </div>
      <div class="logoName">
        <span class="name1">Hotspex</span>
        <span class="name2">STUDIO</span>
      </div>
    </div>
    <div class="centerContent">
      <section class="formContainer">
        <div v-if="feedback === ''">
          <div class="formTop twocol">
            <h2>Forget Password</h2>
          </div>
          <div class="formBody">
            <p><b>Can't remember your password?</b></p>
            <p>
              Enter your email address and we'll send you a link to help you
              reset your password.
            </p>
            <!--  -->
            <div class="form">
              <!-- email -->
              <div class="field">
                <label class="lineLable">Email</label>
                <div class="iconInput">
                  <span class="icon"
                    ><font-awesome-icon icon="envelope"
                  /></span>
                  <input
                    type="email"
                    v-model="email"
                    required
                    class="fullWidth"
                    @keyup.enter="triggerSumbit"
                  />
                </div>
              </div>
              <div class="field twoGridCol">
                <!-- <router-link :to="{ name: 'login' }" class="no-underline">
                  <button class="button lightgreyBtn">CANCEL</button>
                </router-link> -->

                <button class="button lightgreyBtn" @click.prevent="backLogin">
                  CANCEL
                </button>
                <button
                  class="button darkgreyBtn"
                  :disabled="!email_valid"
                  @click.prevent="resetPassword"
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- form feedback -->
        <div
          class="feedback"
          :class="{ expand: feedback !== '' }"
          v-html="feedback"
        ></div>
      </section>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
// import * as jsUtils from "@/utils/jsUtils.js";
export default {
  data() {
    return {
      email: "",
      feedback: "",
      email_valid: false,
    };
  },
  computed: {
    formValid() {
      return this.email_valid;
      // return true;
    },
  },
  watch: {
    email() {
      this.validateEmail();
    },
  },
  methods: {
    validateEmail() {
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.email_valid = emailFormat.test(this.email);
    },
    triggerSumbit() {
      if (this.email_valid) {
        this.resetPassword();
      }
    },
    resetPassword() {
      new Promise((resolve) => {
        wsUtils.ForgotPassword(resolve, {
          EmailId: this.email,
        });
      });

      this.restComplete();

      // pswdReset.then((data) => {
      //   this.restComplete(data);
      // });
    },
    restComplete() {
      this.feedback =
        '<h2>Thank You</h2><p>If the email entered exists in our database, an email will be sent to help you reset your password.</p><p>Please check your inbox <span class="note">(and sometimes junk folder)</span> and follow the instruction proivded in order to set up a new password.</p><p class="note">FYI, your reset link expired after 10 minutes or has been used.</p>';
    },
    backLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.resetPassword {
  background: url("../../public/images/logBG4.jpg") center no-repeat;
  background-color: #000;
  background-size: cover;
  min-height: 100vh;
  background-attachment: fixed;

  display: grid;
  grid-template-rows: auto 1fr;
}

.topBar {
  background-color: var(--hsred);
  height: 75px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;

  .logoIcon {
    background-color: var(--hsgrey);
    height: 100%;
    padding: 10px;

    img {
      max-height: 100%;
      display: block;
      margin: 0;
    }
  }

  .logoName {
    color: #fff;

    display: flex;
    flex-direction: column;
    padding-left: 10px;
    text-align: left;

    .name1 {
      font-size: 26px;
      font-weight: 500;
      letter-spacing: 2px;
    }

    .name2 {
      font-size: 18px;
      letter-spacing: 3px;
      font-weight: 300;
    }
  }
}

.form {
  margin: 2em 0;
}

.failBG {
  margin-bottom: 1em;
  padding: 1em;
}

.field {
  margin-bottom: 2em;

  .lineLable {
    text-transform: uppercase;
    display: block;
    margin-bottom: 8px;
    font-size: 0.85em;
  }

  .iconInput {
    display: grid;
    grid-template-columns: auto 1fr;
    position: relative;
  }

  .icon {
    background-color: var(--iconBGGrey);
    color: #fff;
    height: 32px;
    width: 30px;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
  }

  input.fullWidth {
    width: 100%;
    padding: 0 5px;
  }

  .icon.fixRight {
    background-color: transparent;
    color: var(--iconBGGrey);
  }

  .iconBtn {
    cursor: pointer;
  }

  .icon + input {
    transition: background-color 0.5s;
  }

  .icon + input:focus {
    background-color: var(--lightBlue);
  }
}

.feedback {
  transition: all 0.6s;
  opacity: 0;
  transition-timing-function: ease-in-out;
  text-align: center;
  padding: 0 20px;
  max-height: 0;
  line-height: 1.5em;
}

.feedback.expand {
  padding: 20px;
  max-height: 90vh;
  opacity: 1;
}
</style>

<style lang="scss">
.feedback {
  .note {
    color: var(--hsblue);
  }
}
</style>
